import React from 'react';
import { utils } from '../helpers';

type GetConfig = {
	defaultValue: string;
	validatorArray: string[];
};

type UseQueryReturn = {
	get: (key: string, config?: GetConfig) => string | null;
	set: (key: string, value: string, opts?: SetOptions) => void;
	lastUpdated: number;
	lastValue: string | null;
};

type SetOptions = {
	key?: string;
	popState?: boolean;
	config?: GetConfig;
	updateHistory?: boolean;
};

export default function useQuery(options?: SetOptions): UseQueryReturn {
	const [lastUpdated, setLastUpdated] = React.useState(Date.now());
	const [lastValue, setLastValue] = React.useState<string | null>(null);
	const [query, setQuery] = React.useState(new URLSearchParams(window.location.search));

	const updateFromURL = (url = new URL(window.location.href)) => {
		const searchParams = new URLSearchParams(url.search);
		setQuery(searchParams);
		if (options?.key) {
			setLastValue(searchParams.get(options.key) ?? null);
		}
		setLastUpdated(Date.now());
	};

	React.useEffect(() => {
		updateFromURL();
		if (options?.popState !== false) {
			window.addEventListener('popstate', () => updateFromURL());
		}

		return () => {
			window.removeEventListener('popstate', () => updateFromURL());
		};
	}, []);

	function get<T = string>(key: string, config?: GetConfig): T | null {
		const value = query.get(key);
		if (config?.defaultValue) {
			const isValid = config?.validatorArray?.map((str) => utils.strify(str))?.includes(utils.strify(value) || '');
			if (!isValid) {
				return config.defaultValue as T;
			}
		}
		return value as T;
	}

	const set = (key: string, value: any, opts: SetOptions | undefined = options) => {
		const url = new URL(window.location.href);
		const currentValue = query.get(key);
		if (value === currentValue) return;
		if (value === opts?.config?.defaultValue) value = '';

		if (!value) url.searchParams.delete(key);
		else url.searchParams.set(key, value);

		if (opts?.updateHistory) {
			window.history.pushState({}, '', url.toString());
		} else {
			window.history.replaceState({}, '', url.toString());
		}
		updateFromURL(url);
	};

	return { get, set, lastUpdated, lastValue };
}
